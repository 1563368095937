/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./components/Home";
import Contentblock from "./components/Contentblock";
function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/home" component={Home} />
				<Route path="/" component={Contentblock} />
			</Switch>
		</Router>
	);
}

export default App;
