/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from "react";

import {
  Combobox,
  Input,
  Tooltip,
  Spinner,
  ButtonGroup,
  Button,
  IconSettings,
  InputIcon,
} from "@salesforce/design-system-react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import ImageDetailModal from "../modals/ImageDetailModal";
import ConfirmationModal from "../modals/ConfirmationModal";
import ToastAlert from "../Alert/ToastAlert";
import ContentCard from "../elements/ContentCard";
import Illustration from "./Illustration";
import ContentList from "./ContentList";

import useWindowDimensions from "../hooks/getWindowDimensions";

import {
  GetChannelService,
  GetCmsImages,
  GetSearchableContentTypes,
  SetContent,
  Auth,
  SearchContents,
} from "../routes";

import "../styles.css";

const defaultLimit = 20;

const wsEndpoints = {
  channelService: new GetChannelService(),
  cmsImagesService: new GetCmsImages(),
  searchableContentTypes: new GetSearchableContentTypes(),
  setContentService: new SetContent(),
  authService: new Auth(),
  searchContentsService: new SearchContents(),
};

const limitFilterOptions = [
  {
    id: 1,
    value: 20,
    label: "20 Per Page",
  },
  {
    id: 2,
    value: 40,
    label: "40 Per Page",
  },
  {
    id: 3,
    value: 60,
    label: "60 Per Page",
  },
  {
    id: 4,
    value: 80,
    label: "80 Per Page",
  },
  {
    id: 5,
    value: 100,
    label: "100 Per Page",
  },
];

const initialPageNubers = [
  {
    id: 1,
    pageNumber: 1,
    active: true,
  },
];

function ImageList(props) {
  const { token, sfLogout, sdkContent, clearSdkContent, orgName } = props;
  //const token = useSelector(state => state.auth.token);

  const { width } = useWindowDimensions();

  const [sessionExpired, setSessionExpired] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Sorry, your session has expired. Please refresh the page and try again."
  );
  const [modalShow, setModalShow] = useState(false);
  const [closeOtherDetailModal, setCloseOtherDetailModal] = useState(false);
  const [content, setContent] = useState(null);

  const [logoutModalShow, setLogoutModalShow] = useState(false);

  const [channels, setChannels] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  const [channel, setChannel] = useState(null);
  const [channelSelection, setChannelSelection] = useState([]);

  const [contentType, setContentType] = useState(null);
  const [contentTypeSelection, setContentTypeSelection] = useState([]);

  const [contents, setContents] = useState([]);

  const [loading, setLoading] = useState(false);

  const [mcContent, setMcContent] = useState(null);

  const [nextPageUrl, setNextPageUrl] = useState(null);

  const [pageNumbers, setPageNumbers] = useState(initialPageNubers);

  const [contentLimit, setContentLimit] = useState(20);

  const [searchText, setSearchText] = useState("");

  // SDK data
  const [sdkUpdateContent, setSdkUpdateContent] = useState();

  const [mcSdkContent, setMcSdkContent] = useState();

  const [multiButtonsInFooter, setMultiButtonsInFooter] = useState(false);

  const [mcSDKData, setMcSDKData] = useState(null);

  const fetchContentNodeValue = (nodeKey, ele) => {
    if (ele.nodeType === "DateTime") {
      return { dateTimeValue: ele.dateTimeValue, disabled: !ele.dateTimeValue };
    } else if (ele.nodeType === "Media") {
      const imageUrl = ele.unauthenticatedUrl
        ? ele.unauthenticatedUrl
        : ele.url;
      return { imageUrl, disabled: !imageUrl };
    } else if (ele.nodeType === "MediaSource") {
      const description = ele.unauthenticatedUrl
        ? ele.unauthenticatedUrl
        : ele.url;
      return { description, disabled: !description };
    } else {
      //if(nodeKey === 'Node_Date' || nodeKey === 'Node_RTE' || nodeKey === 'Node_Text' || nodeKey === 'Node_TextArea' || nodeKey === 'Node_Title' || nodeKey === 'Node_URL' ||  nodeKey === 'MultilineText' ){
      return { description: ele.value, disabled: !ele.value };
    }
  };

  const clearMcContent = () => {
    setMcSDKData(null);
    setMcContent(null);
    setContent(null);
    setMcSdkContent(null);
    const updatedContents = [...contents].map((cont) => ({
      ...cont,
      selected: false,
    }));
    setContents(updatedContents);
  };

  useLayoutEffect(() => {
    if (token) {
      const fetchData = async () => {
        const response = await wsEndpoints.channelService.getChannelList(token);
        const result = await response.json();
        // Set Channels list
        let serverChannels = [];
        if (result && result.channels) {
          [...result.channels]
            .filter(
              (ele) =>
                ele.channelType === "PublicUnauthenticated" &&
                ele.isChannelSearchable
            )
            .forEach((ele) => {
              serverChannels.push({
                id: ele.channelId,
                label: ele.channelName,
                type: ele.channelType,
              });
            });

          setChannels(serverChannels);
        } else {
          if (result && result.length) {
            handleError(result[0]);
          } else {
            alert("There is some error, please check with admin.");
          }
        }
      };

      fetchData();
    }
  }, [token]);

  useLayoutEffect(() => {
    if (channels && sdkContent) {
      const { channelId } = props.sdkContent;

      const sdkChannel = [...channels].find((ele) => ele.id === channelId);
      if (sdkChannel) {
        setChannel(sdkChannel);
        setChannelSelection([{ ...sdkChannel }]);
      }
    }
  }, [sdkContent, channels]);

  useLayoutEffect(() => {
    if (channel) {
      const fetchData = async () => {
        const response =
          await wsEndpoints.searchableContentTypes.getSearchableContentTypes(
            token,
            channel.id
          );
        const result = await response.json();
        // Set Content Types
        let serverContentTypes = [];
        if (result && result.items) {
          [...result.items]
            .filter((ele) => ele.isSearchable)
            .forEach((ele) => {
              serverContentTypes.unshift({
                id: ele.id,
                label: ele.label,
                type: ele.name,
              });
            });
          // eslint-disable-next-line no-mixed-operators
          serverContentTypes.sort((a, b) => a.label.localeCompare(b.label));
          setContentTypes(serverContentTypes);

          if (serverContentTypes && sdkContent) {
            const { contentTypeId } = props.sdkContent;
            const sdkContentType = [...serverContentTypes].find(
              (ele) => ele.id === contentTypeId
            );
            if (sdkContentType) {
              setContentType(sdkContentType);
              setContentTypeSelection([{ ...sdkContentType }]);
            }
          }
        } else {
          if (result && result.length) {
            handleError(result[0]);
          } else {
            alert("There is some error, please check with admin.");
          }
        }
      };

      fetchData();
    }
  }, [channel]);

  useLayoutEffect(() => {
    if (contentType) {
      const fetchData = async () => {
        if (props.sdkContent && props.sdkContent.searchText) {
          setSearchText(props.sdkContent.searchText);
          performSearch(props.sdkContent.searchText);
        } else {
          const limit = props.sdkContent?.contentLimit || defaultLimit;

          setContentLimit(parseInt(limit));

          if (contentType.type === "cms_image") {
            getCmsImages(limit, true);
          } else {
            getCmsNodeContent(limit, true);
          }
        }
      };

      fetchData();
    }
  }, [contentType]);

  const fetchOtherContentType = (result) => {
    let otherContents = [];
    [...result.items]
      .filter((ele) => ele.type === contentType.type)
      .forEach((ele, index) => {
        let contentNodes = [];
        let keyindex = 1;
        for (let [key, val] of Object.entries(ele.contentNodes)) {
          const nodeVal = fetchContentNodeValue(key, val);

          const order = Object.keys(
            result.managedContentTypes[contentType.type].nodeTypes
          ).indexOf(key);

          const label = result.managedContentTypes
            ? result.managedContentTypes[contentType.type].nodeTypes[key].label
            : "";
          const nodeType = result.managedContentTypes
            ? result.managedContentTypes[contentType.type].nodeTypes[key]
                .nodeType
            : "";
          const description = result.managedContentTypes
            ? nodeVal && nodeVal.description
              ? nodeVal.description
              : nodeVal && nodeVal.dateTimeValue
              ? nodeVal.dateTimeValue
              : null
            : "";

          contentNodes.push({
            id: keyindex,
            name: `${key}`,
            label,
            description,
            imageUrl: nodeVal && nodeVal.imageUrl ? nodeVal.imageUrl : null,
            dateTimeValue:
              nodeVal && nodeVal.dateTimeValue ? nodeVal.dateTimeValue : null,
            selected: false,
            subTitle: "",
            nodeType,
            disabled: nodeVal.disabled,
            order,
          });

          keyindex++;
        }

        contentNodes.sort(
          (a, b) => a.disabled - b.disabled || a.order - b.order
        );

        const id = ele.managedContentId || ele.title;

        const selected =
          sdkContent && id === sdkContent.id
            ? true
            : mcContent && id === mcContent.id
            ? true
            : false;
        otherContents.push({
          id,
          name: ele.title,
          type: ele.type,
          managedContentId: ele.managedContentId,
          publishedDate: ele.publishedDate,
          selected,
          contentNodes,
        });
      });

    return otherContents;
  };

  const getCmsImages = async (
    contentLimit,
    isLoading = false,
    pageNo = 0,
    action = false
  ) => {
    if (channel) {
      pageNo =
        !action && sdkContent && sdkContent.selectedPage
          ? sdkContent.selectedPage - 1
          : pageNo;

      const contentTypeValue = contentType.type;

      setLoading(isLoading);
      const response = await wsEndpoints.cmsImagesService.getCmsImages(
        token,
        channel.id,
        contentLimit,
        pageNo,
        contentTypeValue
      );
      const result = await response.json();

      if (result && result.items) {
        let imagesContents = [];
        [...result.items].forEach((ele, index) => {
          const imageUrl =
            ele.contentNodes.source.unauthenticatedUrl ||
            ele.contentNodes.source.url;
          const id = ele.managedContentId || ele.title;

          const selected =
            sdkContent && id === sdkContent.id
              ? true
              : mcContent && id === mcContent.id
              ? true
              : false;
          imagesContents.push({
            id,
            name: ele.title,
            imageUrl,
            type: ele.type,
            subTitle: "",
            publishedDate: ele.publishedDate,
            selected,
          });
        });

        setContents(imagesContents);
        setLoading(false);

        showContentModalOnSdkContent(imagesContents, sdkContent);

        setPaggingLink(result.nextPageUrl, pageNo, action);
      } else {
        setLoading(false);
        if (result && result.length) {
          handleError(result[0]);
        } else {
          alert("There is some error, please check with admin.");
        }
      }
    }
  };

  const getCmsNodeContent = async (
    contentLimit,
    isLoading = false,
    pageNo = 0,
    action = false
  ) => {
    if (channel) {
      pageNo =
        !action && sdkContent && sdkContent.selectedPage
          ? sdkContent.selectedPage - 1
          : pageNo;

      const contentTypeValue = contentType.type;

      setLoading(isLoading);
      const response = await wsEndpoints.cmsImagesService.getCmsImages(
        token,
        channel.id,
        contentLimit,
        pageNo,
        contentTypeValue
      );
      const result = await response.json();
      let otherContents = [];
      if (result && result.items) {
        otherContents = fetchOtherContentType(result);

        setContents(otherContents);
        setLoading(false);

        showOtherContentModalOnSdkContent(otherContents, sdkContent);
        setPaggingLink(result.nextPageUrl, pageNo, action);
      } else {
        setLoading(false);
        if (result && result.length) {
          handleError(result[0]);
        } else {
          alert("There is some error, please check with admin.");
        }
      }
    }
  };

  const showContentModalOnSdkContent = (imagesContents, sdkContent) => {
    if (imagesContents && sdkContent) {
      const { id } = sdkContent;
      const sdkContentData = [...imagesContents].find((ele) => ele.id === id);

      if (sdkContentData) {
        setMcContent(sdkContent);
        setContent(sdkContentData);
        clearSdkContent();
        setCloseOtherDetailModal(false);
        setModalShow(true);
      }
    }
  };

  const showOtherContentModalOnSdkContent = (otherContents, sdkContent) => {
    if (otherContents && sdkContent) {
      const { id, childId, imageUrl } = sdkContent;
      const sdkContentData = [...otherContents].find((ele) => ele.id === id);
      if (sdkContentData) {
        if (imageUrl && childId) {
          const imageData = [...sdkContentData.contentNodes].find(
            (ele) => ele.id === childId
          );
          setMcSDKData({ ...imageData, ...sdkContent });
          setContent({ ...imageData, ...sdkContent });
        } else {
          setMcSDKData(sdkContent);
          setContent(sdkContentData);
        }

        setMcSdkContent(sdkContentData);

        clearSdkContent();

        if (imageUrl && childId) {
          setMultiButtonsInFooter(true);
          setMcContent(sdkContent);
          setCloseOtherDetailModal(false);
          setModalShow(true);
        }

        //setModalShow(true);
      }
    }
  };

  const setPaggingLink = (nextPageUrl, pageNo, action = false) => {
    let pageData = [];
    if (action) {
      if (nextPageUrl) {
        const currentPage = action === "next" ? pageNo + 1 : pageNo + 1;
        const nextPage = action === "next" ? pageNo + 2 : pageNo + 2;
        if (currentPage > 0) {
          pageData = [
            {
              id: currentPage,
              pageNumber: currentPage,
              active: true,
            },
            {
              id: nextPage,
              pageNumber: nextPage,
              active: false,
            },
          ];
        } else {
          pageData = [
            {
              id: nextPage,
              pageNumber: nextPage,
              active: true,
            },
          ];
        }
      } else {
        pageData = [
          {
            id: pageNo,
            pageNumber: pageNo,
            active: false,
          },
          {
            id: pageNo + 1,
            pageNumber: pageNo + 1,
            active: true,
          },
        ];
      }
    } else {
      pageData = [
        {
          id: pageNo + 1,
          pageNumber: pageNo + 1,
          active: true,
        },
      ];

      if (nextPageUrl) {
        pageData = [
          ...pageData,
          {
            id: pageNo + 2,
            pageNumber: pageNo + 2,
            active: false,
          },
        ];
      }
    }
    setNextPageUrl(nextPageUrl || null);
    setPageNumbers(pageData);
  };

  const handlePaggingClick = async (pageNo, action) => {
    if (!pageNo) {
      const currentPage = [...pageNumbers].find((ele) => ele.active);
      if (currentPage && action === "next") pageNo = currentPage.pageNumber + 1;
      else if (currentPage) pageNo = currentPage.pageNumber - 1;
    }
    const performAction =
      action === "prv" ? pageNo > 0 : pageNo > 0 && nextPageUrl;

    if (performAction) {
      if (contentType.type === "cms_image") {
        getCmsImages(contentLimit, true, pageNo - 1, action);
      } else {
        getCmsNodeContent(contentLimit, true, pageNo - 1, action);
      }
    }
  };

  const handleClick = (content, isMultiButtonsFooter) => {
    setCloseOtherDetailModal(false);
    if (isMultiButtonsFooter) {
      setMultiButtonsInFooter(true);
    } else {
      setMultiButtonsInFooter(false);
    }
    const contentInfo = {
      height: "auto",
      width: "100%",
    };
    updateBlockContent(content, contentInfo);
  };

  const handleChangeLimit = (e) => {
    setContentLimit(e.target.value);
    if (contentType.type === "cms_image") {
      getCmsImages(e.target.value);
    } else {
      getCmsNodeContent(e.target.value);
    }
  };

  const handleLogoutClick = async () => {
    setLogoutModalShow(true);
  };

  const handleLogoutConfirm = async () => {
    const response = await wsEndpoints.authService.logout(token);
    const result = await response.json();
    setLogoutModalShow(false);
    sfLogout(result);
  };

  const handleSearchButtonClick = () => {
    performSearch(searchText);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      performSearch(searchText);
    }
  };

  const performSearch = async (searchText) => {
    setPageNumbers(initialPageNubers);
    setContentLimit(20);
    setNextPageUrl(null);
    setContents([]);

    if (searchText) {
      const contentTypeValue = contentType.type;
      setLoading(true);
      const response =
        await wsEndpoints.searchContentsService.searchCmsContents(
          token,
          channel.id,
          searchText,
          20
        );
      const result = await response.json();

      try {
        if (contentType.type === "cms_image") {
          if (result && result.items) {
            let imagesContents = [];
            [...result.items]
              .filter((ele) => ele.type === contentTypeValue)
              .forEach((ele, index) => {
                const imageUrl =
                  ele?.contentNodes?.source?.unauthenticatedUrl ||
                  ele?.contentNodes?.source?.url;
                const id = ele.managedContentId || ele.title;

                const selected =
                  sdkContent && id === sdkContent.id
                    ? true
                    : mcContent && id === mcContent.id
                    ? true
                    : false;
                imagesContents.push({
                  id,
                  name: ele.title,
                  imageUrl,
                  type: ele.type,
                  subTitle: "",
                  publishedDate: ele.publishedDate,
                  selected,
                });
              });

            if (result.nextPageUrl) {
              setNextPageUrl(result.nextPageUrl);
            }

            setPaggingLink(result.nextPageUrl, 0, false);

            setContents(imagesContents);
            showContentModalOnSdkContent(imagesContents, sdkContent);
          }
        } else {
          const otherContents = fetchOtherContentType(result);
          setContents(otherContents);
          showContentModalOnSdkContent(otherContents, sdkContent);
          setPaggingLink(result.nextPageUrl, 0, false);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      if (contentType.type === "cms_image") {
        getCmsImages(defaultLimit, true);
      } else {
        getCmsNodeContent(defaultLimit, true);
      }
    }
  };

  const handleError = (error) => {
    if (error && error.errorCode) {
      if (error.errorCode === "INVALID_SESSION_ID") {
        logoutOnSessionExpired(
          true,
          "Sorry, your session has expired. Please refresh the page and try again."
        );
      } else {
        logoutOnSessionExpired(false, error.message);
      }
    }
  };

  const logoutOnSessionExpired = (logout = false, msg = "") => {
    setSessionExpired(true);
    setErrorMsg(msg);
    if (logout) {
      setTimeout(() => sfLogout(), 5000);
    } else {
      setTimeout(() => setSessionExpired(false), 10000);
    }
  };

  const getImageSdkContent = (content, contentInfo) => {
    const { imageUrl } = content;
    const {
      height,
      width,
      alignment = "left",
      autoSizing = true,
      wrapperUrl = "",
      openInNewTab = true,
    } = contentInfo;
    const openNewTabProperty = openInNewTab
      ? `target="_blank" rel="noopener noreferrer"`
      : `target="_self"`;

    const htmlBody = wrapperUrl
      ? `<div  style="font-size: 0.85rem !important;font-family:Helvetica,Arial,sans-serif !important;text-align:
		${alignment};" ><a href="${wrapperUrl}" ${openNewTabProperty}><img height="${height}%" width="${width}%" src="${imageUrl}" /></a></div>`
      : '<div  style="font-size: 0.85rem !important;font-family:Helvetica,Arial,sans-serif !important;text-align: ' +
        alignment +
        ';" ><img height="' +
        height +
        '%" width="' +
        width +
        '%" src="' +
        imageUrl +
        '" /></div>';
    const activePage = [...pageNumbers].find((ele) => ele.active);

    const currentPage =
      props.sdkContent && props.sdkContent.selectedPage
        ? props.sdkContent.selectedPage
        : activePage
        ? activePage.pageNumber
        : 1;

    const data = {
      contentType: contentType.type,
      imageUrl: imageUrl,
      htmlBody,
      width,
      height,
      channelId: channel.id,
      id: content.parentId ? content.parentId : content.id,
      childId: content.parentId ? content.id : null,
      contentTypeId: contentType.id,
      alignment,
      autoSizing,
      selectedPage: currentPage,
      contentLimit,
      searchText,
      wrapperUrl,
      openInNewTab,
    };

    return { htmlBody, data };
  };

  const getTextSdkContent = (content, contentInfo) => {
    const { description } = content;

    const activePage = [...pageNumbers].find((ele) => ele.active);

    const currentPage =
      props.sdkContent && props.sdkContent.selectedPage
        ? props.sdkContent.selectedPage
        : activePage
        ? activePage.pageNumber
        : 1;

    let htmlBody = `${description}`;

    if (content.wrapperText) {
      const newTab = content.openNewTab
        ? `target="_blank" rel="noopener noreferrer"`
        : 'target="_self"';
      htmlBody = `<a style="text-decoration:none"  href="${description}" ${newTab}>${content.wrapperText}</a>`;
    }

    let data = {
      contentType: contentType.type,
      htmlBody,
      channelId: channel.id,
      id: content.parentId,
      childId: content.id,
      nodeType: content.nodeType,
      contentTypeId: contentType.id,
      selectedPage: currentPage,
      contentLimit,
      searchText,
      wrapperText: content.wrapperText || "",
      openInNewTab:
        content && content.hasOwnProperty("openNewTab")
          ? content.openNewTab
          : true,
    };

    if (content.imageUrl) {
      const {
        height,
        width,
        alignment = "left",
        autoSizing = true,
        wrapperUrl = "",
        openInNewTab = true,
      } = contentInfo;

      const openNewTabProperty = openInNewTab
        ? `target="_blank" rel="noopener noreferrer"`
        : "";

      htmlBody = wrapperUrl
        ? `<div  style="font-size: 0.85rem !important;font-family:Helvetica,Arial,sans-serif !important;text-align:
				${alignment};" ><a href="${wrapperUrl}" ${openNewTabProperty}><img height="${height}%" width="${width}%" src="${content.imageUrl}" /></a></div>`
        : '<div  style="font-size: 0.85rem !important;font-family:Helvetica,Arial,sans-serif !important;text-align: ' +
          alignment +
          ';" ><img height="' +
          height +
          '%" width="' +
          width +
          '%" src="' +
          content.imageUrl +
          '" /></div>';
      data = {
        ...data,
        imageUrl: content.imageUrl,
        htmlBody,
        width: content.width ? content.width : width,
        height: content.height ? content.height : height,
        id: content.parentId ? content.parentId : content.id,
        childId: content.parentId
          ? content.id
          : content.childId
          ? content.childId
          : null,
        alignment,
        autoSizing,
        wrapperUrl,
        openInNewTab,
      };
    }

    return { htmlBody, data };
  };

  const updateBlockContent = async (
    content,
    contentInfo,
    isUpdateMcData = false,
    isSetData = false
  ) => {
    let htmlBody, data;

    if (content && content.contentType) {
      const body = getTextSdkContent(content, contentInfo);
      htmlBody = body.htmlBody;
      data = body.data;
      data = { ...data, htmlBody };

      if (data.imageUrl) {
        setCloseOtherDetailModal(false);
        setModalShow(true);
      }
    } else if (content && content.imageUrl) {
      const body = getImageSdkContent(content, contentInfo);
      htmlBody = body.htmlBody;
      data = body.data;
      setCloseOtherDetailModal(false);
      setModalShow(true);
    }

    if (htmlBody && data) {
      if (isUpdateMcData) {
        setMcSDKData(data);
        setMcContent(data);
      }
      props.sdk.setData(data);

      props.sdk.setContent(htmlBody, function (setContent) {
        // block content is now its original content + '.'
      });

      const updatedContents = [...contents].map((cont) => {
        return { ...cont, selected: cont.id === content.id };
      });

      setContents(updatedContents);

      clearMcContent();
      clearSdkContent();
    }

    setContent(content);
  };

  const noSection =
    width < 500
      ? 1
      : width < 700
      ? 2
      : width < 800
      ? 3
      : width <= 901
      ? contentType && contentType.type === "cms_image"
        ? 4
        : 3
      : 6;

  const rowContents = contents.reduce((all, one, i) => {
    const ch = Math.floor(i / noSection);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  }, []);

  const __renderImageCards = () => {
    return (
      <div
        className="slds-scrollable"
        style={{
          height: `${windowHeight}px`,
          paddingTop: "5px",
          paddingLeft: "10px",
          paddingRight: "3px",
          background: "white",
        }}
      >
        {contentType && (
          <>
            {loading ? (
              <Spinner
                size="small"
                variant="base"
                assistiveText={{
                  label: "Main Frame Loading...",
                }}
              />
            ) : (
              rowContents.map((content, index) => {
                return (
                  <div className="slds-grid slds-gutters" key={index}>
                    {content.map((ele) => (
                      <ContentCard
                        content={ele}
                        noSection={noSection}
                        handleClick={handleClick}
                        key={ele.id}
                      />
                    ))}
                  </div>
                );
              })
            )}
          </>
        )}
        {rowContents.length === 0 && (
          <Illustration message="No content to display" />
        )}
      </div>
    );
  };

  let windowHeight = window.innerHeight || 500;
  windowHeight =
    rowContents.length === 0 ? windowHeight - 140 : windowHeight - 230;

  const recordCount = contents.length
    ? `<b>${contents.length}${nextPageUrl ? "+" : ""}</b>  Results Found`
    : " ";

  return (
    <IconSettings iconPath="/icons">
      <div className="slds-p-horizontal_medium slds-p-vertical_small main-container">
        <div
          className="slds-grid slds-wrap"
          style={{
            backgroundColor: "white",
            borderBottom: "1px solid #065264",
          }}
        >
          <div className="slds-col slds-size_1-of-2">
            {orgName ? orgName : ""}{" "}
          </div>
          <div className="slds-col slds-size_1-of-2">
            <button
              className="slds-button slds-float_right"
              onClick={handleLogoutClick}
            >
              LOGOUT
            </button>
          </div>
        </div>
        <div className="slds-grid slds-wrap slds-m-vertical_small">
          <div className="slds-col slds-size_1-of-2 slds-large-size_1-of-3 ">
            <Combobox
              events={{
                onSelect: (event, data) => {
                  setChannel(data.selection ? data.selection[0] : "");
                  setChannelSelection(data.selection);
                  setContents([]);
                  setContentTypes([]);
                  setContentTypeSelection([]);
                  setPageNumbers(initialPageNubers);
                  setSearchText("");
                },
              }}
              labels={{
                label: "Channel",
                placeholderReadOnly: "Select a channel",
                noOptionsFound: "No channel found",
              }}
              options={channels}
              selection={channelSelection}
              value={channel ? channel.label : null}
              variant="readonly"
            />
          </div>
          <div className="slds-col slds-size_1-of-2 slds-large-size_1-of-3 slds-p-left_small">
            {channel && (
              <Combobox
                events={{
                  onSelect: (event, data) => {
                    setContentType(data.selection ? data.selection[0] : "");
                    setContentTypeSelection(data.selection);
                    setSearchText("");
                  },
                }}
                labels={{
                  label: "Content Type",
                  placeholderReadOnly: "Select a content type",
                  noOptionsFound: "No content type found",
                }}
                options={contentTypes}
                selection={contentTypeSelection}
                value={contentType ? contentType.label : null}
                variant="readonly"
                tooltipMenuItemDisabled={<Tooltip />}
                menuPosition="overflowBoundaryElement"
              />
            )}
          </div>
        </div>
        {contentType && (
          <div className="slds-grid slds-wrap slds-m-vertical_small">
            <div className="slds-col slds-size_2-of-2 slds-large-size_2-of-3">
              <Input
                iconLeft={
                  <InputIcon
                    assistiveText={{
                      icon: "Search",
                    }}
                    name="search"
                    category="utility"
                    onClick={handleSearchButtonClick}
                  />
                }
                iconRight={
                  <InputIcon
                    assistiveText={{
                      icon: "Clear",
                    }}
                    name="close"
                    category="utility"
                    onClick={() => {
                      setSearchText("");
                      performSearch();
                    }}
                  />
                }
                placeholder="Search content by title..."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        )}
        <div className="slds-m-bottom_small"></div>
        {contentType && (
          <div className="slds-grid slds-gutters">
            <div className="slds-col slds-size_4-of-12 slds-large-size_4-of-12">
              <div className="slds-grid slds-gutters">
                <div
                  className="slds-m-left_xx-small slds-p-right_small"
                  style={{ paddingLeft: "10px", display: "grid" }}
                >
                  {searchText && (
                    <b style={{ fontSize: "15px" }}>{`"${searchText}"`}</b>
                  )}
                  <span
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      paddingTop: searchText ? "0px" : "10px",
                    }}
                  >
                    {ReactHtmlParser(recordCount)}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="slds-col slds-size_8-of-12 slds-large-size_8-of-12 slds-float_right"
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "flex-end",
              }}
            >
              <div className="slds-p-around_none">
                <select
                  className="slds-m-bottom_x-small limit-dropdown-container"
                  onChange={(e) => {
                    handleChangeLimit(e);
                    // setSearchText("");
                  }}
                  defaultValue={contentLimit}
                >
                  {limitFilterOptions.map((option) => (
                    <option
                      key={option.id}
                      value={option.value}
                      selected={option.value === contentLimit}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <ButtonGroup variant="list">
                <Button
                  iconCategory="utility"
                  iconName="chevronleft"
                  iconSize="large"
                  iconVariant="bare"
                  variant="icon"
                  className="btn-pagination pagging-action-btn"
                  onClick={(e) => handlePaggingClick(null, "prv")}
                />
                {pageNumbers &&
                  pageNumbers.map((ele) => {
                    return (
                      <Button
                        key={ele.id}
                        onClick={(e) => handlePaggingClick(ele.pageNumber)}
                        variant={ele.active ? "brand" : "neutral"}
                        label={ele.pageNumber}
                      />
                    );
                  })}
                <Button
                  iconCategory="utility"
                  iconName="chevronright"
                  iconSize="large"
                  iconVariant="bare"
                  variant="icon"
                  className="btn-pagination pagging-action-btn"
                  onClick={(e) => handlePaggingClick(null, "next")}
                />
              </ButtonGroup>
            </div>
          </div>
        )}
      </div>

      {contentType && contentType.type === "cms_image" ? (
        __renderImageCards()
      ) : (
        <ContentList
          imageDetailModal={handleClick}
          contentType={contentType}
          loading={loading}
          rowContents={rowContents}
          noSection={noSection}
          windowHeight={windowHeight}
          callBackSetContent={updateBlockContent}
          updateSdkContent={setSdkUpdateContent}
          sdkContent={sdkUpdateContent}
          mcSdkContent={mcSdkContent}
          mcContent={mcSDKData}
          clearMcContent={clearMcContent}
          closeModal={closeOtherDetailModal}
        />
      )}

      {modalShow && (
        <ImageDetailModal
          show={modalShow}
          onHide={(hide) => {
            setModalShow(false);
            if (hide) {
              setCloseOtherDetailModal(true);
            }
          }}
          content={content}
          callBackSetContent={updateBlockContent}
          props={props}
          mcContent={mcContent}
          addFooterOptions={multiButtonsInFooter}
        />
      )}
      {logoutModalShow && (
        <ConfirmationModal
          onHide={() => setLogoutModalShow(false)}
          callBack={handleLogoutConfirm}
        />
      )}
      {sessionExpired && <ToastAlert errorMsg={errorMsg} />}
    </IconSettings>
  );
}

export default connect()(ImageList);
