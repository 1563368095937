import { combineReducers } from 'redux'
import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS
} from './action'

function auth(state = {
    isFetching: false,
    isAuthenticated: sessionStorage.getItem('id_token') ? true : false,
    token: sessionStorage.getItem('id_token'),
    orgName: sessionStorage.getItem('org_name')
  }, action) {
    switch (action.type) {
    
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.idToken
      })
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
      })
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      })
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false
      })
    default:
      return state
  }
}

// The quotes reducer
// We combine the reducers here so that they
// can be left split apart above
const cmsApp = combineReducers({
  auth
})

export default cmsApp