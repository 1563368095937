import React from "react";
import ReactQuill, { Quill } from "react-quill";

const icons = Quill.import("ui/icons");
icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

const SizeStyle = Quill.import("attributors/style/size");
SizeStyle.whitelist = ["small", "medium", "large"];
Quill.register(SizeStyle, true);

const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

const Parchment = Quill.import("parchment");
class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    value = parseInt(value);
    if (value === 0) {
      this.remove(node);
      return true;
    } else if (value === "+1" || value === "-1") {
      const indent = this.value(node) || 0;
      value = value === "+1" ? indent + 1 : indent - 1;
    }
    return super.add(node, `${value}em`);
  }
}

const IndentStyle = new IndentAttributor("indent", "text-indent", {
  scope: Parchment.Scope.BLOCK,
  whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"],
});

Quill.register(IndentStyle, true);

let FontStyle = Quill.import("attributors/style/font");
Quill.register(FontStyle, true);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}

function redoChange() {
  this.quill.history.redo();
}

export default function Editor(props) {
  const { content, callbackData, node } = props;

  const modules = {
    toolbar: {
      container: [
        [
          { undo: icons["undo"] },
          { redo: icons["redo"] },
          { font: FontStyle.whitelist },
          { size: SizeStyle.whitelist },
          { background: [] },
          { color: [] },
          "bold",
          "italic",
          "underline",
          "strike",
          { align: AlignStyle.whitelist },
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          "link",
          "clean",
        ],
      ],
      handlers: {
        undo: undoChange,
        redo: redoChange,
      },
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };

  const handleChange = (data) => {
    callbackData(data);
  };

  return (
    <div className="editor-container">
      <div>
        {node && (
          <p className="slds-m-top_medium editor-title">
            {" "}
            {node.label} ({node.nodeType})
          </p>
        )}
        <div className="slds-m-top_x-large">
          <ReactQuill
            modules={modules}
            theme={"snow"}
            escapeHtml={true}
            defaultValue={`${content}`}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
