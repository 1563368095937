/* eslint-disable no-fallthrough */

import React from "react";
import { IconSettings, Button, Modal } from "@salesforce/design-system-react";

export default function ConfirmationModal(props) {
	return (
		<IconSettings iconPath="/icons">
			<Modal
				ariaHideApp={false}
				disableClose
				footer={[
					<Button key='1' label="Cancel" onClick={() => props.onHide()} />,
					<Button
						key='2'
						label="Logout"
						variant="brand"
						onClick={() => props.callBack()}
					/>,
				]}
				isOpen={props.onHide ? true : false}
				onRequestClose={props.onHide}
				prompt="error"
				size="small"
				heading={<span>Logout</span>}>
				<div className="slds-m-around_small slds-align_absolute-center">
					Are you sure you want to logout?
				</div>
			</Modal>
		</IconSettings>
	);
}
