/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */

import React, { useState, useLayoutEffect } from "react";
import {
	IconSettings,
	Modal,
	Button,
	Icon,
	ProgressIndicator
} from "@salesforce/design-system-react";

import 'react-quill/dist/quill.snow.css'; // ES6
import * as moment from "moment";

import ReactHtmlParser from "react-html-parser";
import Editor from "../elements/Editor";
import WrapperView from "../elements/WrapperView";



let insertData = '';
let openNewTab = true;
const steps = [
	{ id: 0, label: 'tooltip label #1' },
	{ id: 1, label: 'tooltip label #2' },

];

let selectedId;

export default function ContentInsertModal(props) {
	const { content, show, onHide, onCallback, selected, updateSdkContent, sdkContent, mcContent, clearMcContent } = props;


	const [records, setRecords] = useState([]);

	const [selectedNode, setSelectedNode] = useState();
	const [editorContent, setEditorContent] = useState();
	const [showEditor, setShowEditor] = useState(false);
	
	const [showWrapperView, setShowWrapperView] = useState(false);

	const [bottomBtns, setBottomBtns] = useState([
		<div className="slds-grid slds-wrap" key="1">
			<div className="slds-size_3-of-12"  >
				<Button label="Previous" disabled style={{ float: 'left' }} />
			</div>
			<div className="slds-size_6-of-12">

			</div>
			<div className="slds-size_3-of-12">
				<Button
					label="Next"
					variant="brand"
					disabled

				/>
			</div>
		</div>
	]);

	const createNextBtn = () => {

		setBottomBtns([
			<div className="slds-grid slds-wrap" key="1">
				<div className="slds-size_3-of-12"  >
					<Button label="Previous" disabled style={{ float: 'left' }} />
				</div>
				<div className="slds-size_6-of-12" style={{ paddingTop: '10px' }}>
					<IconSettings iconPath="/assets/icons">
						<ProgressIndicator
							id="example-progress-indicator"
							steps={steps}

						/>
					</IconSettings>
				</div>
				<div className="slds-size_3-of-12">
					<Button
						label="Next"
						variant="brand"
						onClick={handleNext}

					/>
				</div>
			</div>

		]);
	}

	const createInsertBtn = () => {
		setBottomBtns([
			<div className="slds-grid slds-wrap" key="1">
				<div className="slds-size_3-of-12"  >
					<Button label="Previous" onClick={handleBackClick} style={{ float: 'left' }} />
				</div>
				<div className="slds-size_6-of-12" style={{ paddingTop: '10px' }}>
					<IconSettings iconPath="/icons">
						<ProgressIndicator
							id="example-progress-indicator"
							steps={steps}
							selectedStep={steps[1]}
							completedSteps={steps[0]}
						/>
					</IconSettings>
				</div>
				<div className="slds-size_3-of-12">
					<Button
						label="Done"
						variant="brand"
						onClick={handleInsert}
						style={{ float: 'right' }}
					/>
				</div>
			</div>

		])
	}

	useLayoutEffect(() => {
		if (mcContent && mcContent.htmlBody && mcContent.htmlBody !== 'undefined' && !mcContent.imageUrl) {
			if (mcContent && mcContent.nodeType && (mcContent.nodeType === 'Date' || mcContent.nodeType === 'DateTime')) {
				const newRecords = [...records].map(ele => {
					return { ...ele, selected: ele.id === mcContent.childId };
				});
				setRecords(newRecords);
			} else if(mcContent.nodeType === 'Url' || mcContent.nodeType === 'MediaSource'){
				createInsertBtn();
				setShowWrapperView(true);
			} else {
				createInsertBtn();
				setShowEditor(true);
				setEditorContent(mcContent.htmlBody);
			}
		}
	}, [mcContent]);


	useLayoutEffect(() => {
		if (content && content.contentNodes) {
			selectedId = null;

			if (mcContent && mcContent.nodeType && (mcContent.nodeType === 'Date' || mcContent.nodeType === 'DateTime')) {
				//selectedId = mcContent.childId;
				const newRecords = [...content.contentNodes].map(ele => {
					return { ...ele, selected: ele.id === mcContent.childId };
				});
				setRecords(newRecords);
			}else if(mcContent && mcContent.nodeType && (mcContent.nodeType === 'Url' || mcContent.nodeType === 'MediaSource')){
				
				selectedId = mcContent.childId ? mcContent.childId : mcContent.id;
				
				const selectedData = [...content.contentNodes].find(ele => ele.id === selectedId);
				setSelectedNode(selectedData);

				const newRecords = [...content.contentNodes].map(ele => {
					return { ...ele, selected: ele.id === selectedId };
				});
				setRecords(newRecords);

			}else if (mcContent && mcContent.imageUrl) {
				selectedId = mcContent.childId;
				const newRecords = [...content.contentNodes].map(ele => {
					return { ...ele, selected: ele.id === mcContent.childId };
				});
				setRecords(newRecords);

				createNextBtn();

			} else {
				setRecords(content.contentNodes);
			}

		}
	}, [content]);

	useLayoutEffect(() => {
		//Set the sdk content 
		if (records.length && selected && sdkContent) {
			setSelectedNode(sdkContent);
		}

	}, [selected, records, sdkContent]);



	const handleNext = () => {
		const selectedData = [...records].find(ele => ele.id === selectedId);
		if (selectedData.nodeType === 'Media' && selectedData.imageUrl) {
			onCallback(selectedData);
		} else if (selectedData.nodeType === 'Url' || selectedData.nodeType === 'MediaSource'){
			setShowEditor(false);
			setShowWrapperView(true);
			createInsertBtn();
		} else {
			setShowEditor(true);
			createInsertBtn();
		}
	}


	const handleContentSelect = (contentNode) => {
		const newRecords = [...records].map(ele => {
			return { ...ele, selected: ele.id === contentNode.id };
		});
		setRecords(newRecords);

		if (contentNode.nodeType === 'Media' && contentNode.imageUrl) {
			selectedId = contentNode.id;
			setSelectedNode(contentNode);
			createNextBtn();
		} else if (contentNode.nodeType === 'Url' || contentNode.nodeType === 'MediaSource'){
			selectedId = contentNode.id;
			setSelectedNode(contentNode);
			createNextBtn();
		} else if (contentNode.nodeType === 'Date' || contentNode.nodeType === 'DateTime') {
			onCallback(contentNode)
		} else {
			const htmlContent = contentNode.description ? ReactHtmlParser(contentNode.description) : '';
			setEditorContent(`${htmlContent}`);
			insertData = htmlContent;
			setSelectedNode(contentNode);
			createNextBtn();

			selectedId = contentNode.id;

		}
	}

	const handleChange = (data) => {
		insertData = data;
		setEditorContent({ html: `${data}` });
	}

	const handleWapperTextChangeChange = (data, openInNewTab) => {
		insertData = data;
		openNewTab = openInNewTab
	}

	const handleInsert = () => {
		let selectedData = [...records].find(ele => ele.id === selectedId);

		if(!selectedData && content && content.contentNodes){
			selectedData = [...content.contentNodes].find(ele => ele.id === selectedId);
		}
		
		let updatedContent;

		if (selectedData && (selectedData.nodeType === 'Url' || selectedData.nodeType === 'MediaSource')){
			updatedContent = { ...selectedData, wrapperText: insertData, openNewTab };
		}else{
			updatedContent = { ...selectedData, description: insertData };
		}
		
		onCallback(updatedContent);
		handleCloseModal();
	}



	const handleBackClick = () => {
		setSelectedNode(null);
		setEditorContent('');
		setShowEditor(false);
		setShowWrapperView(false);
		updateSdkContent(null);
		clearMcContent();
		insertData = '';
		openNewTab = true;

		setBottomBtns([
			<Button
				label="Next"
				variant="brand"
				disabled={true}
				key="1"
			/>,
		]);

		selectedId = null;

		if (content && content.contentNodes) {
			setRecords(content.contentNodes)
		} else {
			const newRecords = [...records].map(ele => {
				return { ...ele, selected: false };
			});
			setRecords(newRecords);
		}


	}

	const handleCloseModal = () => {
		handleBackClick();
		onHide();
		clearMcContent();
	};



	const publishedDate = content.publishedDate
		? moment(content.publishedDate).format("DD/MM/YYYY, hh:mm A")
		: "";

	const contentNodeHeader = `<div style="text-align: left"><p class="modal-header-text" style="font-size: 18px; margin-bottom: 5px;">${content.name}</p> <div style="font-size: 10px">Last updated on: ${publishedDate}</div></div>`;

	const selectedClass = {
		width: '99%',
		marginLeft: "2px",
		marginRight: "2px",
		marginTop: "5px",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		wordWrap: "break-word",
		backgroundColor: "#fff",
		backgroundClip: "border-box",
		border: "2px solid #1689EE",
		boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
		borderRadius: '4px'
	}

	const defaultClass = {
		width: '99%',
		marginLeft: "2px",
		marginRight: "2px",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		wordWrap: "break-word",
		backgroundColor: "#fff",
		backgroundClip: "border-box",
		border: "1px solid rgba(0,0,0,.125)",
		marginTop: '5px',
		boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
		borderRadius: '4px',

	}

	return (
		<IconSettings iconPath="/icons">
			<Modal
				isOpen={show}
				footer={[...bottomBtns]}
				heading={ReactHtmlParser(contentNodeHeader)}
				onRequestClose={handleCloseModal}
				ariaHideApp={false}
				size="large"
				directional={showEditor}
			>
				<section >
					{(!showEditor  && !showWrapperView) &&
						<div
							style={{
								height: `500px`,
								paddingTop: "5px",
								paddingLeft: "10px",
								paddingRight: "3px",
								paddingBottom: '70px'
							}}>



							{records.map((ele) => (
								<div
									className="card"
									style={
										!ele.selected
											? { ...defaultClass, pointerEvents: ele.disabled ? 'none' : 'unset' }
											:
											{
												...selectedClass
											}
									}
									onClick={() => handleContentSelect(ele)}
									disabled
									key={`${ele.id}`}>
									<div className="card-body" style={{ padding: '15px', }}>
										<p style={{ color: '#3E3E3C', fontSize: '14px' }}> {ele.label} ({ele.nodeType})</p>
										<div className={ele.disabled ? `box-container disableContainer ${ele.nodeType}` : `box-container  ${ele.nodeType}`}>
											{ele.imageUrl ?
												<img
													src={ele.imageUrl}
													className="card-img"
													alt="Card"
													style={{ height: "150px", objectFit: "contain", width: '300px' }}
												/> :

												<p style={{
													color: '#706E6B',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													display: '-webkit-box',
													WebkitLineClamp: "3" /* number of lines to show */,
													WebkitBoxOrient: "vertical",
												}} dangerouslySetInnerHTML={{ __html: ReactHtmlParser(`${ele.description}`) }} ></p>

											}
										</div>


									</div>
									{ele.selected && <div style={{
										position: 'absolute', height: '100%', width: '100%',
										textAlign: 'end',
										background: 'linear-gradient(to top right,transparent 50%,#1689EE 0) top right/30px 30px no-repeat,transparent',
										color: 'white',
										overflow: 'hidden',
									}}>
										<IconSettings
											iconPath="/icons"
											className="slds-m-right_x-small slds-text-body_x_small slds-align_absolute-center">

											<Icon
												assistiveText={{ label: 'Announcement' }}
												category="utility"
												name="check"
												style={{ fill: 'white', color: 'white' }}
												size="x-small"
											/>
										</IconSettings>
									</div>}

								</div>
							))}
						</div>
					}

					{showEditor &&
						<Editor
							callbackData={handleChange}
							content={`${editorContent}`}
							node={selectedNode}
						/>}

					{(showWrapperView && selectedNode) &&
						<WrapperView
							callbackData={handleWapperTextChangeChange}
							node={selectedNode}
							mcContent={mcContent}
						/>}

				</section>
			</Modal>
		</IconSettings>
	);
}
;
