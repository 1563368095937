/* eslint-disable no-fallthrough */

import React from "react";
import { ToastContainer, Toast } from "@salesforce/design-system-react";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";

export default function ToastAlert(props) {
	/*
	 * RENDERING
	 */

	const errorHeading = props.heading || "Error!";

	return (
		<IconSettings iconPath="/icons">
			<ToastContainer>
				<Toast
					labels={{
						heading: errorHeading,
						details: props.errorMsg,
					}}
					variant="error"
				/>
			</ToastContainer>
		</IconSettings>
	);
}
