/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */

import React, { useState, useEffect } from "react";
import {
	Checkbox,
	RadioButtonGroup,
	Radio,
	IconSettings,
	Modal,
	Button,
	ProgressIndicator,
	Input,
	Tooltip,
} from "@salesforce/design-system-react";
import * as moment from "moment";
import ReactHtmlParser from "react-html-parser";

const alignment = ["Left", "Center", "Right"];

const steps = [
	{ id: 0, label: "tooltip label #1" },
	{ id: 1, label: "tooltip label #2" },
];

export default function ImageDetailModal(props) {
	const { content, mcContent, addFooterOptions } = props;

	const [imageWidthPercentage, setWidthImagePercentage] = useState(100);
	const [imageHeightPercentage, setHeightImagePercentage] = useState(100);

	const [imageAlignment, setImageAlignment] = useState("Left");
	const [autoSizing, setAutoSizing] = useState(true);

	const [directional, setDirectional] = useState(false);
	const [openInNewTab, setOpenInNewTab] = useState(true);
	const [wrapperUrl, setWrapperUrl] = useState("");

	const [footerSection, setFooterSection] = useState([
		<Button
			label="Done"
			variant="brand"
			onClick={() => {
				props.onHide();
			}}
		/>,
	]);

	const handleSizeChange = (value) => {
		setWidthImagePercentage(value);
		setHeightImagePercentage(value);
	};

	const handleChangeAlignment = (e) => {
		setImageAlignment(e.target.value);
	};

	const handleBackClick = () => {
		props.onHide();
	};

	const handleDoneClick = () => {
		props.onHide(true);
	};

	useEffect(() => {
		setContentInMc();
	}, [imageAlignment, imageWidthPercentage, wrapperUrl, openInNewTab]);

	useEffect(() => {
		if (addFooterOptions && !directional) {
			setDirectional(true);
			setFooterSection([
				<div className="slds-grid slds-wrap" key="1">
					<div className="slds-size_3-of-12">
						<Button
							label="Previous"
							onClick={handleBackClick}
							style={{ float: "left" }}
						/>
					</div>
					<div
						className="slds-size_6-of-12"
						style={{ paddingTop: "10px" }}>
						<IconSettings iconPath="/icons">
							<ProgressIndicator
								id="example-progress-indicator"
								steps={steps}
								selectedStep={steps[1]}
								completedSteps={steps[0]}
							/>
						</IconSettings>
					</div>
					<div className="slds-size_3-of-12">
						<Button
							label="Done"
							variant="brand"
							onClick={handleDoneClick}
							style={{ float: "right" }}
						/>
					</div>
				</div>,
			]);
		} else {
			setDirectional(false);
		}
	}, [addFooterOptions]);

	useEffect(() => {
		if (mcContent && content && mcContent.id === content.id) {
			const { alignment, width, height } = mcContent;
			setWidthImagePercentage(width);
			setHeightImagePercentage(height);
			setImageAlignment(alignment);
			setAutoSizing(mcContent.autoSizing);

			const url = mcContent.wrapperUrl ? mcContent.wrapperUrl : "";
			setWrapperUrl(url);

			const tabOpen = !openInNewTab ? openInNewTab : 
				mcContent && mcContent.hasOwnProperty("openInNewTab")
					? mcContent.openInNewTab
					: true; //mcContent.openInNewTab ? mcContent.openInNewTab :  false;
			setOpenInNewTab(tabOpen);
		}
	}, []);

	const setContentInMc = () => {
		const alignment = imageAlignment;
		const link = content.imageUrl;
		const height = imageHeightPercentage;
		const width = imageWidthPercentage;

		const contentInfo = {
			title: content.name,
			imageurl: link,
			height,
			width,
			alignment,
			link,
			autoSizing,
			wrapperUrl,
			openInNewTab,
		};
		props.callBackSetContent(content, contentInfo, true);
	};

	const publishedDate = content.publishedDate
		? moment(content.publishedDate).format("DD/MM/YYYY, hh:mm A")
		: "";

	const publishedDateView = publishedDate
		? `<div style="font-size: 10px">Last updated on: ${publishedDate}</div>`
		: "";

	const contentNodeHeader = `<div style="text-align: left"><p class="modal-header-text" style="font-size: 18px; margin-bottom: 5px;">${content.name}</p> ${publishedDateView}</div>`;

	return (
		<IconSettings iconPath="/icons">
			<Modal
				ariaHideApp={false}
				isOpen={props.show}
				size="large"
				directional={directional}
				footer={[...footerSection]}
				onRequestClose={props.onHide}
				heading={ReactHtmlParser(contentNodeHeader)}>
				<section
					className="slds-p-around_medium"
					style={{
						height: `510px`,
						paddingTop: "20px",
						paddingLeft: "10px",
						paddingRight: "10px",
						paddingBottom: "10px",
					}}>
					{content && content.label && (
						<p style={{ fontSize: "15px", paddingBottom: "10px" }}>
							{" "}
							{content.label} ({content.nodeType})
						</p>
					)}
					<div
						className="slds-m-top_large"
						style={{
							border: "1px solid #1798c1",
							padding: "10px",
							height: `400px`,
						}}>
						<div className="slds-form-element slds-m-bottom_small slds-m-top_x-large">
							<div className="slds-grid">
								<div className="slds-col  slds-size_5-of-8">
									<label className="slds-form-element__label">
										<span className="slds-slider-label">
											<span className="slds-slider-label__label">
												Image Size
											</span>
											<span className="slds-slider-label__range">
												0 - 100%
											</span>
										</span>
									</label>
									<div className="slds-form-element__control">
										<div className="slds-slider">
											<input
												type="range"
												className="slds-slider__range"
												value={imageWidthPercentage}
												onChange={(event) => {
													handleSizeChange(
														event.target.value
													);
												}}
												disabled={autoSizing}
											/>
											<span className="slds-slider__value">
												{imageWidthPercentage}%
											</span>
										</div>
									</div>
								</div>
								<div
									className="slds-col  slds-size_3-of-8 slds-p-left_xx-large"
									style={{ marginTop: "40px" }}>
									<Checkbox
										labels={{
											label: "Scale to Fit",
										}}
										onChange={(e) => {
											const autoSizingValue =
												e.target.checked;
											setAutoSizing(autoSizingValue);
											if (autoSizingValue) {
												setHeightImagePercentage(
													"auto"
												);
												setWidthImagePercentage("100");
											}
										}}
										checked={autoSizing}
									/>
								</div>
							</div>
						</div>
						<div className="slds-form-element slds-m-bottom_small slds-m-top_x-large">
							<span className="slds-form-element__label">
								Image Alignment
							</span>
							<RadioButtonGroup
								onChange={(event) =>
									handleChangeAlignment(event)
								}
								name="Alignment">
								{alignment.map((day) => (
									<Radio
										key={day}
										id={day}
										labels={{ label: day }}
										value={day}
										variant="button-group"
										checked={imageAlignment === day}
									/>
								))}
							</RadioButtonGroup>
						</div>
						<div className="slds-form-element slds-m-bottom_small slds-m-top_x-large">
							<div className="slds-grid">
								<div
									className="slds-col   slds-size_8-of-8"
									style={{ paddingRight: "50px" }}>
									<Input
										id="field-level-help"
										label="Link"
										fieldLevelHelpTooltip={
											<Tooltip
												id="field-level-help-tooltip"
												align="top left"
												content="Image Hyperlink"
											/>
										}
										placeholder="https://example.com"
										value={wrapperUrl}
										onChange={(e) =>
											setWrapperUrl(e.target.value)
										}
									/>
								</div>
								
							</div>
						</div>
					</div>
				</section>
			</Modal>
		</IconSettings>
	);
}
