import React from "react";

export default function Illustration(props) {
	return (
		<div className="slds-illustration slds-illustration_small">
			<svg
				className="slds-illustration__svg"
				viewBox="0 0 454 213"
				aria-hidden="true"
				xmlns="http://www.w3.org/2000/svg"
				style={{
					width: "100%",
					maxWidth: "700px",
					maxHeight: "700px",
				}}>
				<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g transform="translate(-70.000000, -94.000000)">
						<g>
							<g transform="translate(124.500000, 222.000000)">
								<g fill="#FFFFFF">
									<path
										vectorEffect="non-scaling-stroke"
										d="M18.9209988,1.95433401 L33.259296,51.443436 C33.5666778,52.5043744 32.9557995,53.613617 31.8948612,53.9209988 C31.7139843,53.9734036 31.5266126,54 31.3382972,54 L2.6617028,54 C1.5571333,54 0.661702805,53.1045695 0.661702805,52 C0.661702805,51.8116846 0.688299176,51.6243129 0.74070397,51.443436 L15.0790012,1.95433401 C15.386383,0.893395645 16.4956256,0.282517358 17.556564,0.589899164 C18.2152102,0.780726338 18.7301717,1.29568777 18.9209988,1.95433401 Z"></path>
								</g>
								<g
									className="slds-illustration__stroke-secondary"
									strokeLinecap="round"
									strokeWidth="3">
									<polygon
										vectorEffect="non-scaling-stroke"
										strokeLinejoin="round"
										points="17 0.323943662 34 54 -1.81721305e-12 54"></polygon>
									<path
										vectorEffect="non-scaling-stroke"
										d="M17,4.6953125 C17,43.0456294 17,62.6471919 17,63.5 C17,62.6471919 17,43.0456294 17,4.6953125 Z"></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M17,29.3239437 C22.3333333,35.7851611 25,39.1184944 25,39.3239437 C25,39.1184944 22.3333333,35.7851611 17,29.3239437 Z"
										strokeLinejoin="round"
										transform="translate(21.000000, 34.323944) scale(-1, 1) translate(-21.000000, -34.323944) "></path>
								</g>
							</g>
							<g transform="translate(145.000000, 194.000000)">
								<g
									transform="translate(1.000000, 0.000000)"
									fill="#FFFFFF">
									<path
										vectorEffect="non-scaling-stroke"
										d="M25.6478873,0 L50.879042,84.4273253 C51.1953215,85.4856452 50.5937789,86.5999782 49.535459,86.9162577 C49.3496374,86.9717906 49.1567264,87 48.9627843,87 L2.33299037,87 C1.22842087,87 0.332990367,86.1045695 0.332990367,85 C0.332990367,84.8060578 0.361199757,84.6131469 0.416732643,84.4273253 L25.6478873,0 Z"></path>
								</g>
								<g
									className="slds-illustration__stroke-secondary"
									strokeLinecap="round"
									strokeWidth="3">
									<polygon
										vectorEffect="non-scaling-stroke"
										strokeLinejoin="round"
										points="26.5 0 52.5 87 0.5 87"></polygon>
									<path
										vectorEffect="non-scaling-stroke"
										d="M26.5,2.58642578 C26.5,61.0261034 26.5,90.9972948 26.5,92.5 C26.5,90.9972948 26.5,61.0261034 26.5,2.58642578 Z"></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M15.6478873,42 C22.314554,49.078692 25.6478873,52.7453587 25.6478873,53 C25.6478873,52.7453587 22.314554,49.078692 15.6478873,42 Z"
										strokeLinejoin="round"></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M27.6478873,68 C36.9812207,57.078692 41.6478873,51.7453587 41.6478873,52 C41.6478873,51.7453587 36.9812207,57.078692 27.6478873,68 Z"
										strokeLinejoin="round"></path>
								</g>
							</g>
							<g
								transform="translate(404.500000, 245.000000) scale(-1, 1) translate(-404.500000, -245.000000) translate(348.000000, 226.000000)"
								className="slds-illustration__stroke-secondary"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="3">
								<polyline
									vectorEffect="non-scaling-stroke"
									points="0 38 47.5 0 80.5 26"></polyline>
								<polyline
									vectorEffect="non-scaling-stroke"
									points="71 17 80.5 9 113 36"></polyline>
							</g>
							<g transform="translate(72.000000, 262.500000)">
								<path
									vectorEffect="non-scaling-stroke"
									d="M153.962142,26.4644491 C151.225735,20.0143094 144.944776,15.5029106 137.633892,15.5029106 C135.619663,15.5029106 133.683612,15.8453541 131.878328,16.4764392 C128.451481,11.1704266 122.567406,7.66985447 115.883789,7.66985447 C109.491267,7.66985447 103.830159,10.8721423 100.350851,15.7935668 C98.9589956,14.968161 97.3423157,14.4956341 95.6177606,14.4956341 C94.1083143,14.4956341 92.6815102,14.8576334 91.4157672,15.5014039 C87.9975328,6.58722215 79.5098304,0.275259875 69.5804557,0.275259875 C60.4632836,0.275259875 52.5615782,5.59684366 48.6837305,13.3681823 C46.3912034,12.266973 43.8314865,11.6515593 41.1312741,11.6515593 C32.4373504,11.6515593 25.1998844,18.0312998 23.6476214,26.4644491 L153.962142,26.4644491 Z"
									className="slds-illustration__fill-secondary"></path>
								<path
									vectorEffect="non-scaling-stroke"
									d="M13,25 L143,25"
									className="slds-illustration__stroke-secondary"
									strokeWidth="3"
									strokeLinecap="round"></path>
								<path
									vectorEffect="non-scaling-stroke"
									d="M0,25 L450,25"
									className="slds-illustration__stroke-secondary"
									strokeWidth="3"
									strokeLinecap="round"></path>
							</g>
							<g transform="translate(344.000000, 247.000000)">
								<g transform="translate(0.293436, 0.054545)">
									<path
										vectorEffect="non-scaling-stroke"
										d="M165.428708,41.9454545 L0.0995432562,41.9454545 C0.0336614956,41.2089487 0,40.4630069 0,39.7090909 C0,26.2132599 10.7866531,15.2727273 24.0926641,15.2727273 C27.7492016,15.2727273 31.215485,16.0989227 34.3199502,17.5772977 C39.5712028,7.14424616 50.271428,0 62.6175975,0 C76.0636257,0 87.5573893,8.47383452 92.1862485,20.441159 C93.9002755,19.5768947 95.8324059,19.0909091 97.8764479,19.0909091 C100.211783,19.0909091 102.401037,19.7252784 104.285841,20.8333889 C108.997403,14.2263569 116.663488,9.92727273 125.320028,9.92727273 C138.043441,9.92727273 148.627152,19.2146805 150.834755,31.4671412 C151.487388,31.3631046 152.156394,31.3090909 152.837838,31.3090909 C159.117096,31.3090909 164.340238,35.8953699 165.428708,41.9454545 Z"
										className="slds-illustration__fill-secondary"></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M32.7065637,40.4454545 L173.706564,40.4454545"
										className="slds-illustration__stroke-secondary"
										strokeWidth="3"
										strokeLinecap="round"></path>
								</g>
							</g>
							<g transform="translate(105.000000, 189.000000)">
								<g
									transform="translate(106.000000, 48.000000)"
									fill="#FFFFFF">
									<polygon
										vectorEffect="non-scaling-stroke"
										points="121.5 14.5 158.5 14.5 158.5 0.5 47.5 0.5 47.5 14.5 93.5 14.5 93.5 35.5 121.5 35.5 121.5 14.5"></polygon>
									<rect
										x="0"
										y="0"
										width="48"
										height="14"></rect>
								</g>
								<g
									transform="translate(106.000000, 62.000000)"
									className="slds-illustration__fill-secondary">
									<polygon
										vectorEffect="non-scaling-stroke"
										points="93.3109375 0.456640625 93.3109375 21.6722656 120.925 21.6722656 121.823047 1.13242188 158.5 0.456640625 158.5 54.5 48.5 54.5 48.5 0.693359375"></polygon>
									<rect
										x="0.5"
										y="0.5"
										width="48"
										height="54"></rect>
								</g>
								<g>
									<rect
										className="slds-illustration__stroke-primary"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
										x="154.5"
										y="48.5"
										width="110"
										height="68"></rect>
									<polygon
										vectorEffect="non-scaling-stroke"
										className="slds-illustration__stroke-primary"
										strokeeidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
										points="264.5 62.5 264.5 48.5 154.5 48.5 154.5 62.5 199.5 62.5 199.5 83.5 227.5 83.5 227.5 62.5"></polygon>
									<path
										vectorEffect="non-scaling-stroke"
										d="M96.5,7.5 L96.5,7.5 C109.754834,7.5 120.5,18.245166 120.5,31.5 L120.5,41.5 L72.5,41.5 L72.5,31.5 C72.5,18.245166 83.245166,7.5 96.5,7.5 Z"
										className="slds-illustration__fill-secondary"
										transform="translate(96.500000, 24.500000) scale(-1, 1) rotate(90.000000) translate(-96.500000, -24.500000) "></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M105.624512,4.97216797 C94.4226888,4.50244141 85.9366862,9.85058594 80.1665039,21.0166016 C79.8051287,21.7159073 79.4687138,22.3615749 79.1572595,22.9536042 L80.1089196,17.7968272 C80.4778395,16.9245818 81.0906215,15.4885626 81.9472656,13.4887695 C86.7151693,7.81103516 89.2679036,4.97216797 89.6054688,4.97216797 C89.9430339,4.97216797 92.2714844,3.71272786 96.5908203,1.19384766 L101.309082,0.447753906 L105.624512,0.447753906 L105.624512,4.97216797 Z"
										fill="#FFFFFF"></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M96.5,7.5 L96.5,7.5 C109.754834,7.5 120.5,18.245166 120.5,31.5 L120.5,41.5 L72.5,41.5 L72.5,31.5 C72.5,18.245166 83.245166,7.5 96.5,7.5 Z"
										className="slds-illustration__stroke-primary"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
										transform="translate(96.500000, 24.500000) scale(-1, 1) rotate(90.000000) translate(-96.500000, -24.500000) "></path>
									<rect
										className="slds-illustration__fill-secondary"
										x="106.5"
										y="0.5"
										width="110"
										height="48"></rect>
									<rect
										className="slds-illustration__stroke-primary"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
										x="106.5"
										y="0.5"
										width="110"
										height="48"></rect>
									<rect
										className="slds-illustration__stroke-primary"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
										x="106.5"
										y="62.5"
										width="48"
										height="54"></rect>
									<rect
										className="slds-illustration__stroke-primary"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
										x="106.5"
										y="48.5"
										width="48"
										height="14"></rect>
									<path
										vectorEffect="non-scaling-stroke"
										d="M219,66 C219,68.765 216.765,71 214,71 C211.235,71 209,68.765 209,66 C209,63.235 211.235,61 214,61 C216.765,61 219,63.235 219,66 Z"
										className="slds-illustration__fill-primary"></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M214,69 L214,74"
										className="slds-illustration__stroke-primary"
										strokeWidth="4"
										strokeLinecap="round"></path>
									<circle
										vectorEffect="non-scaling-stroke"
										className="slds-illustration__fill-primary"
										cx="164"
										cy="72"
										r="3"></circle>
									<circle
										vectorEffect="non-scaling-stroke"
										className="slds-illustration__fill-primary"
										cx="164"
										cy="107"
										r="3"></circle>
									<circle
										vectorEffect="non-scaling-stroke"
										className="slds-illustration__fill-primary"
										cx="255"
										cy="72"
										r="3"></circle>
									<circle
										vectorEffect="non-scaling-stroke"
										className="slds-illustration__fill-primary"
										cx="255"
										cy="107"
										r="3"></circle>
									<circle
										vectorEffect="non-scaling-stroke"
										className="slds-illustration__fill-primary"
										cx="145"
										cy="72"
										r="3"></circle>
									<circle
										vectorEffect="non-scaling-stroke"
										className="slds-illustration__fill-primary"
										cx="145"
										cy="107"
										r="3"></circle>
									<circle
										vectorEffect="non-scaling-stroke"
										className="slds-illustration__fill-primary"
										cx="116"
										cy="72"
										r="3"></circle>
									<circle
										vectorEffect="non-scaling-stroke"
										className="slds-illustration__fill-primary"
										cx="116"
										cy="107"
										r="3"></circle>
									<path
										vectorEffect="non-scaling-stroke"
										d="M289.928751,96.2971422 L298,116.518658 L280,116.518658 L288.071249,96.2971422 C288.275982,95.784207 288.857768,95.5343604 289.370703,95.7390942 C289.625359,95.8407378 289.827108,96.0424867 289.928751,96.2971422 Z"
										className="slds-illustration__fill-primary"></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M300.428751,103.813271 L305.5,116.518658 L293.5,116.518658 L298.571249,103.813271 C298.775982,103.300336 299.357768,103.050489 299.870703,103.255223 C300.125359,103.356867 300.327108,103.558616 300.428751,103.813271 Z"
										className="slds-illustration__fill-primary"></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M93.4287513,96.2971422 L101.5,116.518658 L83.5,116.518658 L91.5712487,96.2971422 C91.7759825,95.784207 92.3577681,95.5343604 92.8707033,95.7390942 C93.1253588,95.8407378 93.3271077,96.0424867 93.4287513,96.2971422 Z"
										className="slds-illustration__fill-primary"
										transform="translate(92.500000, 106.517446) scale(-1, 1) translate(-92.500000, -106.517446) "></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M76.9287513,103.813271 L82,116.518658 L70,116.518658 L75.0712487,103.813271 C75.2759825,103.300336 75.8577681,103.050489 76.3707033,103.255223 C76.6253588,103.356867 76.8271077,103.558616 76.9287513,103.813271 Z"
										className="slds-illustration__fill-primary"
										transform="translate(76.000000, 110.275510) scale(-1, 1) translate(-76.000000, -110.275510) "></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M360,116.5 L372,116.5"
										className="slds-illustration__stroke-primary"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M0,116.5 L350,116.5"
										className="slds-illustration__stroke-primary"
										strokewdth="3"
										strokeLinecap="round"
										strokeLinejoin="round"></path>
								</g>
							</g>
							<g
								transform="translate(160.000000, 126.000000)"
								className="slds-illustration__stroke-secondary"
								strokeLinecap="round"
								strokeWidth="3">
								<path
									vectorEffect="non-scaling-stroke"
									d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"></path>
								<path
									vectorEffect="non-scaling-stroke"
									d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5"></path>
								<path
									vectorEffect="non-scaling-stroke"
									d="M18,27.5 L83.0004985,27.5"></path>
								<path
									vectorEffect="non-scaling-stroke"
									d="M0,27.5 L8,27.5"></path>
							</g>
							<g
								transform="translate(271.000000, 95.000000)"
								className="slds-illustration__stroke-secondary"
								strokeLinecap="round"
								strokeWidth="3">
								<path
									vectorEffect="non-scaling-stroke"
									d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"></path>
								<path
									vectorEffect="non-scaling-stroke"
									d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5"></path>
								<path
									vectorEffect="non-scaling-stroke"
									d="M18,27.5 L83.0004985,27.5"></path>
								<path
									vectorEffect="non-scaling-stroke"
									d="M0,27.5 L8,27.5"></path>
							</g>
							<g
								transform="translate(402.000000, 164.000000)"
								className="slds-illustration__stroke-secondary"
								strokeLinecap="round"
								strokeWidth="3">
								<g transform="translate(31.713442, 25.088326) rotate(-15.000000) translate(-31.713442, -25.088326) translate(4.713442, 6.588326)">
									<path
										vectorEffect="non-scaling-stroke"
										d="M31.0360707,3.43528591 C31.0360707,3.43528591 40.5802283,0.671893051 42.6488424,10.6908663"
										transform="translate(36.842457, 6.888440) rotate(41.000000) translate(-36.842457, -6.888440) "></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M40.4282002,10.1797377 C40.4282002,10.1797377 49.9723578,7.4163448 52.0409719,17.435318"
										transform="translate(46.234586, 13.632892) scale(-1, 1) rotate(-41.000000) translate(-46.234586, -13.632892) "></path>
									<path
										vectorEffect="non-scaling-stroke"
										d="M0.730284783,29.5865514 C0.730284783,29.5865514 10.2744424,26.8231586 12.3430565,36.8421318"></path>
									<path
										vectoreeffect="non-scaling-stroke"
										d="M12.7302848,29.5865514 C12.7302848,29.5865514 22.2744424,26.8231586 24.3430565,36.8421318"
										transform="translate(18.536671, 33.039705) scale(-1, 1) translate(-18.536671, -33.039705) "></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
			<div className="slds-text-longform">
				<h3 className="slds-text-heading_medium">{props.message}</h3>
			</div>
		</div>
	);
}
