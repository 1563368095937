class SetContent {
	async setContentData() {
		const url = `setContent`;
		return fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
	}
}
export default SetContent;
