/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from "react";
import { Spinner } from "@salesforce/design-system-react";

import Illustration from "./Illustration";
import ContentCardWoImage from "../elements/ContentCardWoImage";
import ContentInsertModal from "../modals/ContentInsertModal";
const selected = false;

export default function ContentList(props) {
	const {
		contentType,
		loading,
		rowContents,
		noSection,
		windowHeight,
		imageDetailModal,
		callBackSetContent,
		mcSdkContent,
		closeModal = false,
		clearMcContent,
	} = props;

	const [modalShow, setModalShow] = useState(true);
	const [selectedContent, setSelectedContent] = useState();

	useLayoutEffect(() => {
		if (closeModal) {
			setSelectedContent(null);
			setModalShow(false);
			clearMcContent();
		}
	}, [closeModal]);

	useLayoutEffect(() => {
		if (mcSdkContent && !selectedContent) {
			setSelectedContent(mcSdkContent);
			setModalShow(true);
		}
	}, [mcSdkContent, selectedContent]);

	const handleContentInsert = (content, editorContent = "", contentId) => {
		if (content && content.imageUrl) {
			imageDetailModal(content, true);
		} else if (content) {
			callBackSetContent(content, contentId);
		}
	};

	const handleClick = (content) => {
		setSelectedContent(content);
		setModalShow(true);
	};

	const handleConentSelection = (content, editorContent = "") => {
		if (content && !content.imageUrl) {
			setModalShow(false);
		}
		handleContentInsert(
			{ ...content, parentId: selectedContent.id, contentType },
			editorContent
		);
	};

	return (
		<div
			className="slds-scrollable"
			style={{
				height: `${windowHeight}px`,
				paddingTop: "5px",
				paddingLeft: "10px",
				paddingRight: "3px",
				background: "white",
			}}>
			{contentType && (
				<>
					{loading ? (
						<Spinner
							size="small"
							variant="base"
							assistiveText={{
								label: "Main Frame Loading...",
							}}
						/>
					) : (
						rowContents.map((content, index) => {
							return (
								<div className="slds-grid" key={index}>
									{content.map((ele) => (
										<ContentCardWoImage
											content={ele}
											noSection={noSection}
											onContentSelection={
												handleContentInsert
											}
											key={ele.id}
											onRowClick={handleClick}
											{...props}
										/>
									))}
								</div>
							);
						})
					)}
				</>
			)}
			{rowContents.length === 0 && (
				<Illustration message="No content to display" />
			)}

			{modalShow && selectedContent && (
				<ContentInsertModal
					show={modalShow}
					onHide={() => setModalShow(false)}
					onCallback={handleConentSelection}
					content={selectedContent}
					noSection={props.noSection}
					selected={selected}
					{...props}
				/>
			)}
		</div>
	);
}
