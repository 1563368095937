import { serverUrl } from "../Constants";
class Auth {
	async logout(token) {
		const url = `${serverUrl}/auth/logout?token=${token}`;
		return fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
	}
}

export default Auth;
