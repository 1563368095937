import React from "react";

export default function Home() {
	
	return (
		<div
			className="home-container">
			<article
				className="slds-card home-txt-container"
				>
				<div className="slds-card__body slds-card__body_inner">
					<div className="slds-text-heading_medium">
						Welcome to CMS Sync, brought to you by Horizontal
						Digital. In order to utilize CMS Sync, please navigate
						to an email in Content Builder and locate our app under
						custom blocks.
					</div>
				</div>
			</article>
		</div>
	);
}
