import React from "react";
import * as moment from "moment";
import ReactHtmlParser from "react-html-parser";

export default function ContentCard(props) {
  const { content } = props;

  const imgWidth =
    props.noSection === 1
      ? "100%"
      : props.noSection === 2
      ? "49.5%"
      : props.noSection === 3
      ? "33.3%"
      : props.noSection === 4
      ? "24.3%"
      : props.noSection === 6
      ? "16.4%"
      : "16.4%";
  const publishedDate = content.publishedDate
    ? moment(content.publishedDate).format("DD/MM/YYYY, hh:mm A")
    : "";

  return (
    <div
      className={
        !content.selected
          ? "card card-img-container-unselected"
          : "card card-img-container-selected"
      }
      style={
        !content.selected
          ? {
              width: imgWidth,
            }
          : {
              width: imgWidth,
            }
      }
      onClick={() => props.handleClick(content)}
      key={content.id}
    >
      <img
        src={content.imageUrl}
        className="card-img"
        alt="Card"
        style={{ height: "150px", objectFit: "contain" }}
      />
      <div className="card-body">
        {!content.selected && <div style={{ padding: "10px" }}></div>}
        <div className="card-title slds-text-body_small slds-align_absolute-center">
          {content.name}
        </div>

        <div className="slds-align_absolute-center">
          <span>
            {ReactHtmlParser(
              `<div style="font-size: 10px">Last updated on: ${publishedDate}</div>`
            )}
          </span>
        </div>
        {content.selected && (
          <div className="slds-align_absolute-center">
            <button className="slds-button">Update</button>
          </div>
        )}
      </div>
    </div>
  );
}
