import { serverUrl } from "../Constants";
class GetSearchableContentTypes {
	async getSearchableContentTypes(token, channelId) {
		const url = `${serverUrl}/searchableContentTypes?token=${token}&channelID=${channelId}`;
		return fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
	}
}
export default GetSearchableContentTypes;
