import { serverUrl } from "../Constants";
class GetCmsImages {
	async getCmsImages(token, channelId, contentLimit, pageNo, contentType) {
		const url = `${serverUrl}/cmsContent?token=${token}&channelID=${channelId}&contentType=${contentType}&contentLimit=${contentLimit}&pageNo=${pageNo}`;

		return fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
	}
}

export default GetCmsImages;
