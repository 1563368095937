/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import BlockSDK from "blocksdk";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";

import ImageList from "./ImageList";
import { serverUrl } from "../Constants";
import { receiveLogin, receiveLogout } from "../redux/action";
import ToastAlert from "../Alert/ToastAlert";

function Contentblock() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const token = useSelector((state) => state.auth.token);
  const orgName = useSelector((state) => state.auth.orgName);
  const [stateToken, setStateToken] = useState(token);
  const [stateOrgName, setStateOrgName] = useState(orgName);
  const [error, setError] = useState(null);
  const [mcToken, setMcToken] = useState(null);
  const [mcError, setMcError] = useState(null);

  const [sdkContent, setSdkContent] = useState(null);
  let sdk = new BlockSDK({ blockEditorWidth: 900 });
  let windowObjectReference = null;
  const [isPopUpBlocked, setIsPopUpBlocked] = useState(false);

  useEffect(() => {
    sdk.getData((data) => {
      if (!sdkContent) {
        setSdkContent(data);
      }
    });
  }, []);

  useEffect(() => {
    console.log('queries', window.location.search, token, mcError, mcToken);

    let queries = queryString.parse(window.location.search);

    if (queries.error) {
      setMcError(queries.error);
      setMcToken(null);
      sessionStorage.removeItem("mc_token");
    } else {
      setMcError(null);
    }

    console.log('mcError', queries.error, mcError, queries.code);
    
    if (!queries.error && !mcError) {

      setError(null);

      if (queries.code) {
        setMcToken(queries.code);
        sessionStorage.setItem("mc_token", 'valid');
      }else{
        openMCLoginPopup();
      }

      if (queries.token) {
        
        console.log('mc token', token);

        // get the URL parameters which will include the auth token
        if (window.opener) {
          // send them to the opening window
          window.opener.postMessage({
            token: queries.token,
          });
          // close the popup
          window.close();
        }

      } else if (!token) {
        sessionStorage.removeItem("mc_token");
        openLoginPopup();
      }  else if(token && !sessionStorage.getItem('mc_token')){
         openMCLoginPopup();
      }
    }else{
      console.log('mc error', token);
    }

    
    
    //if (!mcError) {
      //let queries = queryString.parse(window.location.search);

      /*console.log('queries', queries, token);
      if (queries.error) {
        setMcError(queries.error);
      } else {
        setMcError(null);
      }*/

      /*if (!queries.error) {
        //if (queries.code) {
          //setMcToken(queries.code);
          setError(null);
          if (queries.token) {
            // get the URL parameters which will include the auth token
            if (window.opener) {
              // send them to the opening window
              window.opener.postMessage({
                token: queries.token,
              });
              // close the popup
              window.close();
            }
          } else if (!token) {
            openLoginPopup();
          }
        /*} else {
          openMCLoginPopup();
        }*/
      //}
    //}
  }, []);

  const openLoginPopup = () => {
    window.removeEventListener("message", receiveMessage);
    if (windowObjectReference === null || windowObjectReference.closed) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      windowObjectReference = window.open(
        `${serverUrl}/auth/login`,
        "salesforcelogin",
        "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100"
      );
      try {
        windowObjectReference.focus();
        setIsPopUpBlocked(false);
      } catch (e) {
        setIsPopUpBlocked(true);
      }
      // add the listener for receiving a message from the popup
      window.addEventListener(
        "message",
        (event) => receiveMessage(event),
        false
      );
    }
  };

  const openMCLoginPopup = () => {
    const {REACT_APP_MC_DOMAIN, REACT_APP_MC_CLIENT_ID, REACT_APP_MC_REDIRECT_URL } = process.env;
    const mcAuthUrl = `${REACT_APP_MC_DOMAIN}/v2/authorize?response_type=code&client_id=${REACT_APP_MC_CLIENT_ID}&redirect_uri=${REACT_APP_MC_REDIRECT_URL}`;
    console.log('mcAuthUrl', mcAuthUrl);
    window.open( mcAuthUrl, "_self", "" );
    window.close();
  };

  const receiveMessage = (event) => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    const { data } = event;
    console.log('receiveMessage', data);
    if (data.token) {
      loginUser(data.token);
    }
  };

  const loginUser = (token) => {
    if (token) {
      const tokens = token.split("<<>>");
      if (tokens && tokens.length) {
        if (tokens[0].startsWith("error-")) {
          const error = tokens[0].replace("error-", "");
          const errorObj =
            tokens.length > 1
              ? { error, msg: tokens[1] }
              : {
                  error,
                  msg: "There is some error, Please contact with admin.",
                };
          setError(errorObj);
        } else {
          setError(null);
          setStateToken(tokens[0]);
          sessionStorage.setItem("id_token", tokens[0]);
          
          if (tokens.length > 1) {
            setStateOrgName(tokens[1]);
            sessionStorage.setItem("org_name", tokens[1]);
          }
          // Dispatch the success action
          dispatch(receiveLogin(tokens[0]));
          
          if(!mcToken){
            openMCLoginPopup();
          }
        }
      }
    }
  };

  const clearSdkContent = () => {
    setSdkContent(null);
  };

  const sfLogin = () => {
    openLoginPopup();
  };

  const sfLogout = (result) => {
    if (result && result.url) {
      const win = window.open(
        result.url,
        "salesforcelogout",
        "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100"
      );
      setTimeout(() => {
        win.close();
        sessionStorage.removeItem("id_token");
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("org_name");
      }, 2000);
    } else {
      sessionStorage.removeItem("id_token");
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("org_name");
    }
    // Dispatch the success action
    dispatch(receiveLogout(token));
  };

  const authorizedView = () => {
    return (
      <div className="slds-page-header" style={{ padding: "5px 5px" }}>
        <ImageList
          sdk={sdk}
          token={stateToken}
          sfLogout={sfLogout}
          sdkContent={sdkContent}
          clearSdkContent={clearSdkContent}
          orgName={stateOrgName}
        />
      </div>
    );
  };



  

  let unauthorizedView = () => {
    return (
      <div
        style={{
          marginTop: "10%",
          marginBottom: "30%",
          background: "#b0c4df",
        }}
      >
        {mcError ? (
          <article
            className="slds-card"
            style={{ marginLeft: "20%", marginRight: "20%" }}
          >
            <div className="slds-card__body slds-card__body_inner slds-m-vertical_large slds-text-heading_large slds-align_absolute-center">  
              <a href="mailto:hello@horizontal.com" class="slds-text-link_reset">You are not authorized to access CMS Sync. Please reach out to 
                  <span class="slds-text-link"> Horizontal Digital</span>.
              </a>
            </div>
          </article>
        ) : sessionStorage.getItem('mc_token') ? (
          <article
            className="slds-card"
            style={{ marginLeft: "20%", marginRight: "20%" }}
          >
            <div className="slds-card__body slds-card__body_inner slds-m-vertical_large slds-text-heading_large slds-align_absolute-center">
              {isPopUpBlocked
                ? "Popup blocker is detected! Please add this site to your exception list and click Try Again."
                : "A salesforce login popup will ask you to authenticate to CMS Salesforce Org."}
            </div>
            <footer className="slds-card__footer">
              <button
                className="slds-card__footer-action slds-text-heading_medium slds-button slds-align_absolute-center"
                onClick={() => sfLogin()}
              >
                {isPopUpBlocked
                  ? "Try again"
                  : "If nothing happens, click here"}
              </button>
            </footer>
          </article>
        ) : (<div className="slds-card__body slds-card__body_inner slds-m-vertical_large slds-text-heading_large slds-align_absolute-center">
            <b>Loading...</b>
        </div>)
          
        }
      </div>
    );
  };

  return (
    <React.Fragment>
      {isAuthenticated && !mcError && sessionStorage.getItem('mc_token') ? authorizedView() : unauthorizedView()}
      {error && <ToastAlert errorMsg={error.msg} heading={error.error} />}
    </React.Fragment>
  );
}

export default connect()(Contentblock);
