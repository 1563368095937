import React from "react";
import { Icon } from "@salesforce/design-system-react";
import * as moment from "moment";
import ReactHtmlParser from "react-html-parser";

export default function ContentCardWoImage(props) {
	const { content, onRowClick } = props;

	const publishedDate = content.publishedDate
		? moment(content.publishedDate).format("DD/MM/YYYY, hh:mm A")
		: "";

	const handleClick = (content) => {
		onRowClick(content);
	};

	return (
		<div
			className="card slds-col  slds-size_1-of-3 card-without-img-container"
			
			onClick={() => handleClick(content)}
			key={`${content.id}`}>
			<div className={
					!content.selected
						? "card-body card-without-img-container-unselected"
						: "card-body card-without-img-container-selected"
				}>
				<div className="slds-grid slds-wrap" style={{minHeight: '70px'}}>
					<div className="slds-col slds-size_1-of-8 slds-large-size_1-of-8 slds-align_absolute-center">
					<Icon
								assistiveText={{ label: 'unknown' }}
								category="doctype"
								name="unknown"
								size="medium"
							/>
					</div>
					<div className="slds-col slds-size_7-of-8 slds-large-size_7-of-8 slds-align-middle card-content-container">
					<p className="card-content">{ReactHtmlParser(`${content.name}`)}</p>
						<span>{ReactHtmlParser(`<div style="font-size: 10px">Last updated on: ${publishedDate}</div>`)}</span>
					</div>
				</div>

			
			</div>


		</div>
	);
}
