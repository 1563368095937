import { serverUrl } from "../Constants";

class GetChannelService {
	async getChannelList(token) {
		const url = `${serverUrl}/channels?token=${token}`;
		return fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
	}
}

export default GetChannelService;
