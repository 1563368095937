import { serverUrl } from "../Constants";
class SearchContents {
	async searchCmsContents(token, channelId, searchText, contentLimit) {
		const url = `${serverUrl}/searchCmsContent?token=${token}&channelID=${channelId}&searchText=${searchText}&contentLimit=${contentLimit}`;

		return fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
	}
}

export default SearchContents;
